import React, { useState } from 'react'
import { Container } from '../styles'
import Index from '../Navigation';
import styled from 'styled-components';
import imgmain from '../../../images/demoproduct/img4.webp';
import img1 from '../../../images/demoproduct/img1.webp';
import img2 from '../../../images/demoproduct/img2.webp';


const Smallimage = styled.div`
height: 90px;
width: 90px;
background: white;

img{
width: 100%;
height: 100%;

}
`;
const images = [img2, imgmain];
const ProductOverview = () => {
    var keys = 0;
    const [currentImage, setcurrentImage] = useState(imgmain);

    function overview(key) {
        setcurrentImage(images[key]);
        keys = key;
    }

    const previousData = () => {
        keys = keys - 1;
        if (keys <= 0) {
            keys = images.length - 1;
        }

        setcurrentImage(images[keys]);
    }
    const forwardData = () => {
        keys = keys + 1;
        if (keys === images.length) {
            keys = 0;
        }

        setcurrentImage(images[keys]);
    }

    return (
        <div>
            <Index />
            <Container style={{ width: '80%' }}>
                <div className='product__componentsjs w-full'>
                    <div className=' flex gap-5 w-full mt-3 product_component_image_section'>
                        <div className=' product_image_wrapper flex gap-5 '>
                            <div className='side__component_js'>
                                {/* <Smallimage src={img1} className='bg-gray-200 shadow cursor-pointer rounded-lg mt-4 border-black border-2' /> */}
                                {images.map((image, key) => (
                                    <Smallimage onClick={() => overview(key)} className='bg-gray-200 shadow-md cursor-pointer shadow-pointer rounded-lg mt-4 border-transparent border-2'>
                                        <img src={image} />
                                    </Smallimage>
                                ))}



                            </div>

                            <div className='bg-gray-200 relative rounded-lg shadow  border flex justify-center items-center  image__overview_section'>
                                <img src={currentImage} />

                                <div className='absolute  center__section_image  flex justify-between'>
                                    <div onClick={previousData} className="bg-white flex cursor-pointer justify-center border items-center rounded-full shadow icon__wrapper">
                                        <i className='fa-solid fa-chevron-left'></i>
                                    </div>
                                    <div onClick={forwardData} className="bg-white flex justify-center cursor-pointer border items-center rounded-full shadow icon__wrapper">
                                        <i className='fa-solid fa-chevron-right'></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='h1 font-bold text__compenents_ss'>
                            <h1>Apple iPhone 11 - 64GB | 128GB (Unlocked) A2111 (CDMA + GSM) Smartphone</h1>
                            <div className='flex mt-4 items-center'>
                                <div className='font-bold font__size text-gray-700'>Posted By: </div>
                                <div className=' font__size text-gray-700'>Simonadev 2hours ago</div>
                            </div>

                            <div className='bg-white shadow mt-2 p-5 pb-5 border-1  bg__cart_option'>
                                <button className='bg-blue-800 outline-none border-none rounded-md text-white w-full clicking__In'>Chat With Seller</button>
                            </div>
                        </div>


                    </div>

                </div>

            </Container>
        </div>
    )
}

export default ProductOverview
