class Removeclasses {

    remove(array = [], classname) {
        array.forEach((nodelist) => {
            nodelist.classList.remove(...classname)
        })
    }
}

const _removeclass = new Removeclasses();
export default _removeclass;