import React from 'react';
import styled from 'styled-components';
import cars from '../../../images/category/cars.webp';

const Carding = styled.div`
width: 90px;
height: 90px;




@media(max-width: 48rem){
width: 100px !important;
height: 100px !important;
}

.text__meduim{
@media(max-width: 48rem){
  font-size: 12px !important;
}
}




`;

const MyCard = ({ name = "Automobiles", img = 'fa-home', onclick }) => {
  return (
    <Carding onClick={onclick} className=' border hover:rounded-full flex flex-col justify-center items-center shadow cursor-pointer  cart_category'>
      <i className={`fa-regular ${img} text-blue-700`}></i>
      
      <div className="cart_name  text-center font-medium  text__meduim  mt-2" >{name}</div>
    </Carding>
  )
}

export default MyCard
