import React from 'react'
import { Container } from '../../layout/components/styles'
import Index from '../../layout/components/Navigation';
import image1 from "../../images/demo/image2.png";
import styled from 'styled-components';
import Footer from '../../layout/components/footer/footer';
const Gallery = () => {
    return <div className='image__gallery  cursor-pointer rounded-md'>
        <img src={image1} />
    </div>
}

const Carddetails = ({ classname }) => {
    return <div className={classname}>
        <Heading>Items Overview</Heading>
        <div className='mt-5'>
            <p>Rotai Royal Omega Smart Health Care Massage Chair Grey</p>
            <div className='mt-1 round___underhood'>
                <li>10years Manufacturing waranty</li>
                <li>Anti-Pinch Safety System Massage Chair</li>
                <li>3D Rollers with Silicon Heads</li>
                <li> Full Body Airbags</li>
                <li> • Innovative 6-segment track</li>
            </div>
        </div>
    </div>
}

const Heading = styled.div`
font-size: 17px;
font-weight: bold;

`;
const Viewpgae = () => {
    return (
        <div>
            <Index />
            <Container>
                <div className='details mt-20'>
                    <div className='flex gap-10'>
                        <div className=' wrapper__image_section'>
                            <div className='relative bg-gray-200 caption__banner_image'>
                                <div className=' mx-auto image__over___'>
                                    <img src={image1} alt="" />
                                    <div className="absolute caption__icon_banner p-1 px-2 font-bold rounded-full text-white">1 of 7 Photos</div>
                                </div>

                                <div className='flex justify-between absolute arrow_controls'>
                                    <div className="bg-white shadow bg-silver rounded-full flex items-center cursor-pointer justify-center icons__arrow">
                                        <i className='fa-regular fa-chevron-left'></i>
                                    </div>

                                    <div className="bg-white shadow bg-silver rounded-full flex items-center cursor-pointer justify-center icons__arrow">
                                        <i className='fa-regular fa-chevron-right'></i>
                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-center mt-3 gap-3'>
                                <Gallery />
                                <Gallery />
                                <Gallery />
                                <Gallery />
                            </div>
                            <div>
                                <Carddetails classname={'border_b_bottom__right py-10'}></Carddetails>
                                <Carddetails classname={'mt-4 border_b_bottom__right py-10 py-2'}></Carddetails>
                            </div>

                        </div>


                        <div className='caption___vendor '>
                            <div className='shadow-md rounded-md border p-5 caption__vendor_box'>
                                <div>Posted by</div>

                                <div>
                                    <div className='name__caption_cendor text-gray-600'>Test Name</div>
                                    <div className="verified bg-red-200 px-2 rounded-md inline-block">
                                        <i className='fa-solid fa-circle-check text-red-600'></i>
                                        <span className='text-red-500 font-medium ml-2'>Verified User</span>
                                    </div>
                                    <div className='joined_here_now'>Joined on march</div>
                                    <div className='mt-3 '>2items live</div>
                                </div>

                                <button className='btn__chat_width_seller mt-3 text-white rounded-md'> <i className='fa-regular fa-message'></i>  Chat With Seller</button>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>


            <Footer />
        </div>
    )
}

export default Viewpgae
