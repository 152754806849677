import React, { useState } from 'react'
import { Container } from '../styles';
import axios from 'axios';


const LocationSelect = ({ location }) => {

    return <div className='absolute p-3 bg-white rounded-md shadow location_desktop_dropdown'>
        {location.map((town) => (
            <div className='hover:bg-gray-400 rounded-md text-black location__inline_copy'>
                <i className='fa-regular fa-chevron-right mr-1'></i>  {town.name}
            </div>
        ))}
    </div>
}

const Search = ({ logo }) => {
    const [search, setsearch] = useState('');
    const [showsearch, setshowsearch] = useState(false);
    const [searchText, setsearchText] = useState('');
    const [selectlocation, setselectlocation] = useState(false);

    const [location, setlocation] = useState([]);
    axios.get('location').then((res) => {
        setlocation(res.data.data);
    })

    function hangdlechnagelocation() {
        setselectlocation(!selectlocation);
    }

    function searchdata(e) {

        setshowsearch(true);
        setsearchText(e.target.value.trim())
    }

    return (
        <div className='search_wrapper   flex items-center'>
            <Container className='flex justify-center gap-4 items-center'>
                <div className="  logo">
                    <img src={logo} />
                </div>
                <div className='flex cursor-pointer relative hover:text-blue-700 hover:bg-gray-200 location'>
                    <div className='change_logcation' onClick={hangdlechnagelocation}>Change location</div>
                    <div className=''>
                        <i class="fa-solid fa-map-pin" style={{ fontSize: 20 }}></i>
                    </div>
                    {selectlocation && <LocationSelect location={location} />}

                </div>

                <div className=" relative flex seach_input rounded-full">
                    <div className="flex items-center  justify-center search_icon">
                        <i className='fa-regular fa-search' style={{ fontSize: 15 }}></i>

                    </div>
                    <input type="text"
                        onChange={searchdata}
                        className='search__' placeholder='Search for anything' />
                    {/* <div className=" menu_searchbar">
                        <div className=' top_menu_search px-4 justify-between flex items-center '>
                            <div>All Category</div>

                            <div>
                                <span className=' rounded-full cursor-pointer  hover:bg-gray-400 icon__down'>
                                    <i className='fa-regular fa-chevron-down'></i>
                                </span>
                            </div>
                        </div>

                    </div> */}
                    {showsearch && searchText && <div className="shadow mt-2 search__dropdown_control p-2">
                        <div className="flex items-center rounded-full cursor-pointer gap-2 mt-1 mx-auto hover:bg-gray-300  search_items_list px-1">
                            <div>category</div> <div className='font-bold '>Categorr</div>
                        </div>
                        <div className="flex items-center rounded-full cursor-pointer gap-2 mt-1 mx-auto hover:bg-gray-300  search_items_list px-1">
                            <div>category</div> <div className='font-bold '>Categorr</div>
                        </div></div>}

                </div>


                <button className=" bg-blue-600 capitalize button_search">Search</button>
                <div className='post_ur_adds'>
                    <a href="" className='text-blue-500'>Post Your Adds</a>
                </div>

            </Container>
        </div>
    )
}

export default Search
