
import { Provider } from 'react-redux';
import store from './store/store';
import axios from 'axios';
import Layout from './layout';

import { useSelector } from 'react-redux';
import './styles/app.style.css';
import './styles/responsive.style.css';
import './styles/app.loader.css';

 axios.defaults.baseURL = '/server/public/api/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';





// import './App.css';

function App() {

  // const Cartitems = useSelector(state => state.cart);

  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );


}



export default App;
