import React, { useState } from 'react'

const Steptwo = () => {
    const [select, setselect] = useState(null);
    const data = [
        { caption: "A market Place Online", text: "I'm just getting started", },
        { caption: "I Have an offline shop already", text: "i already have an online experience selling", }
    ];

    const selected = (e, key) => {

        setselect(key)

    }

    return (
        <div className='pt-5'>

            <div className='mt-5'>
                <h1 className='font__h1 font-extrabold text-blue-800'>Let’s get started. Which of these best describes you?</h1>
                <p className='font-medium text-gray-600 media__mobile'>let help you setup your store account successfully</p>
            </div>

            <div className='w-full  mt-4 flex gap-5 little__model_wrap '>
                {data.map((text, key) => (

                    <div className={`rounded-md flex items-center w-full border select__box ${select == key ? 'select_active' : ''}`} onClick={(e) => selected(e, key)}>
                        <div className='mx-auto flex justify-between items-center gap-3 select__box__inner'>

                            <div>
                                <div>
                                    <div className='font-bold'>{text.caption}</div>
                                    <p className='text__movement__color'>{text.text}</p>
                                </div>


                            </div>
                            <div className="icon__circled border flex justify-center items-center bg-white cursor-pointer rounded-full">
                                <div className="bg-blue-500 rounded-full inner_circled"></div>
                            </div>
                        </div>
                    </div>


                ))}

            </div>
        </div>
    )
}

export default Steptwo
