import React, { useEffect, useState } from 'react';
import logo from '../../../images/logo/logo.png';
import { json, useNavigate } from 'react-router-dom';
import axios from 'axios';



const Location = () => {
    const locations = ['bamenda', 'douala', 'limbe', 'mbambui'];
    const navigate = useNavigate();
    const [location, setlocation] = useState([]);
    const [sublocation, setsublocation] = useState([]);
    const [quater, setquater] = useState(null);
    const [region, setregion] = useState('');
    const [id, setid] = useState(1);



    const regionResponce = (id) => {
        setid(id);
        const regs = location.filter((reg) => reg.id == id);
    }

    useEffect(() => {
        axios.get('/location').then((res) => {
            setlocation(res.data.data);
        });
    }, [])

    const navigate_tosignup = () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        user.quater = quater;

        sessionStorage.setItem('user', JSON.stringify(user));
        

        // dispatch(user);
        // navigate('/signup')
    }

    return (
        <div className='flex bg-blue-300 h-screen justify-center items-center animate__section_bg'>
            <div className='bg-white shadow p-10 rounded-md mx-auto content_signUp'>
                <div className='content_signUp__innner mx-auto'>
                    <div className="flex items-center">
                        <div className="grid_logo">
                            <img src={logo} />
                        </div>
                        <div className='font-bold font__h2'>Jabuh</div>
                    </div>


                    <div className=' mt-4'>
                        <h1 className='font-bold font__h1_big line_height_h1 '>Where will your business be located?</h1>
                        <p className='mt-4 font-normal'>We’ll use your location to set up your default shipping rates, recommended apps, and more.</p>

                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>region  <i class="fa-regular fa-house"></i> </label>
                            <select

                                onChange={(e) => regionResponce(e.target.value)}
                                className='font-control mt-2 border-black border rounded-md  selected__data capitalize'>
                                {location.map((text) => {
                                    return <option value={text.id}>{text.name}</option>
                                })}


                            </select>
                        </div>
                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>Quater</label>
                            <select className='font-control mt-2 border-black border rounded-md d selected__data capitalize' onChange={(e) => setquater(e.target.value)}>
                                <option value="">select quater</option>
                                {location.map((mlocation) => {
                                    return mlocation.sublocations.map((msublocation) => {
                                        if (msublocation.location_id == id) {
                                            return <option value={msublocation.id}>{msublocation.name}</option>
                                        }

                                    })
                                })}
                            </select>
                        </div>
                        <div className='flex gap-3 justify-between mt-20'>
                            <div>
                                <button className='p-2' onClick={''}> <i className='fa-regular fa-chevron-left'></i> back</button>
                            </div>
                            <div>
                                <button className='p-2 px-3 btn bg-blue-700 rounded-md text-white' onClick={navigate_tosignup}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location
