import React from 'react'
import Home from '../pages/home'
import { RouteWrapper } from '../routes'
import Inputs from '../pages/components/Inputs'
import _removeclass from '../pages/UI_logic/removeClasslist'

const Layout = () => {
    const handle_event_click = (event) => {
        const currentTarget = event.currentTarget;
        const closetdiv = event.target.closest('div');
        const allinputs = currentTarget.querySelectorAll('.myInput');
        if (!closetdiv.classList.contains('selected_border')) {
            _removeclass.remove(allinputs, ['selected_border', 'shadow'])
        } else {
            _removeclass.remove(allinputs, ['selected_border', 'shadow']);
            closetdiv.classList.add('selected_border', 'shadow')

        }

    }
    return (
        <div className='layout__wrapper_Link' onClick={handle_event_click}>
            <RouteWrapper />
        </div>
    )
}

export default Layout
