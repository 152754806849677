import React, { useState } from 'react'
import Inputs from '../../components/Inputs';

const StepThree = ({ email, fullname, change_email, change_fullname }) => {
    const [select, setselect] = useState(null);
    const data = [
        { caption: "Do you market Place Online", text: "I'm just getting started", },
        { caption: "I Have an offline shop already", text: "i already have an online experience selling", }
    ];


    const selected = (e, key) => {

        setselect(key)

    }

    return (
        <div className='pt-5'>

            <div className='mt-5'>
                <h1 className='font__h1 font-extrabold text-blue-800'>Some Basic Details</h1>
                <p className='font-medium text-gray-600  media__mobile'>Please Provide some basic details about yourselve and shop</p>
            </div>

            <div className='w-full  mt-4 flex gap-5  little__model_wrap '>
                <div className='input_component'>
                    <label>Full Name</label>
                    <div className='w-full'>
                        <Inputs
                            onchange={change_fullname}
                            value={fullname}
                            inputplaceholder={false} styles={{ width: "100%" }} instyles={{
                                width: '100%',
                                borderTopLeftRadius: "inherit",
                                borderBottomLeftRadius: "inherit"

                            }} />
                    </div>
                </div>
                <div className='input_component'>
                    <label>Email Address</label>
                    <div className='w-full'>
                        <Inputs
                            onchange={change_email}
                            value={email}
                            inputplaceholder={false} styles={{ width: "100%" }} instyles={{
                                width: '100%',
                                borderTopLeftRadius: "inherit",
                                borderBottomLeftRadius: "inherit"
                            }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepThree
