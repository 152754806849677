import React from 'react';
import logo from '../../../images/logo/logo.png';
import { Button } from 'antd';



const Signup = () => {
    const locations = ['bamenda', 'douala', 'limbe', 'mbambui'];


    return (
        <div className='flex bg-blue-300 h-screen justify-center items-center animate__section_bg'>
            <div className='bg-white shadow p-10 rounded-md mx-auto content_signUp '>
                <div className='content_signUp__innner mx-auto'>
                    <div className="flex items-center">
                        <div className="grid_logo">
                            <img src={logo} />
                        </div>
                        <div className='font-bold font__h2'>Jabuh</div>
                    </div>


                    <div className=' mt-4'>
                        <h1 className='font-bold font__h1_big line_height_h1 '>Create your Account to get Started </h1>
                        <p className='mt-3'>just enter your details to get started and enjoy the ease of selling your product</p>


                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>email</label>
                            <input type="text" className='font-control pl-2 bg-gray-100 mt-2 border-black border rounded-md d selected__data capitalize'></input>
                        </div>

                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>password</label>
                            <input type="text" className='font-control pl-2 mt-2 bg-gray-100 border-black border rounded-md d selected__data capitalize'></input>
                        </div>

                        <div className='flex gap-3 justify-between mt-10'>
                            <Button className='w-full text___login hover:bg-blue-600 custome__btn_blue hover:text-white text-white bg-blue-800 py-3 rounded font-medium'>Create your jabuh account</Button>


                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Signup
