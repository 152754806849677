
import { createRoot } from "react-dom/client";
// import Layout from "./layout";
import './styles/app.style.css';
import App from "./App";




//root
const html_print = document.querySelector('#root');
const root = createRoot(html_print);

root.render(<App />);



