import React from 'react'
import Index from '../../layout/components/Navigation'
import { Container } from '../../layout/components/styles'
import MyCard from '../../layout/components/store/SubcatTitle'
import Cartone from '../../layout/components/products/cartone';
import styled from 'styled-components';
import Footer from '../../layout/components/footer/footer';


const Splitter = styled.div`


`;

const Tittle_captions = ({ caption, text }) => {

    return <div className="flex justify-center flex-col rounded-md  p-2 px-4 bg-gray-200 py-1 square_boxes">
        <div className='card__price_caption  text__gray__100'>{caption}</div>
        <div className='font___price mt-1 font-semibold'>{text}</div>
    </div>
}


const Products = () => {
    const likable = (e) => {
        e.currentTarget.classList.toggle('icon__red_like');
    }


    return (
        <div>
            <Index />
            <Container style={{width: '70%'}}>
                <div className='flex justify-between product__wrapper_block '>
                    <div className='grid__sidebar'>
                        <div className='pt-2'>
                            <div className='flex items-center gap-3'>
                                <a href="" className='flex gap-1 items-center'>
                                    <div>Classified</div>
                                    <div className='flex items-center justify-center icons__red'>
                                        <i class="fa-regular fa-angles-right"></i>
                                    </div>
                                </a>
                                <a href="" className='flex gap-1 items-center'>
                                    <div>Classified</div>
                                    <div className='flex items-center justify-center icons__red'>
                                        <i class="fa-regular fa-angles-right"></i>
                                    </div>
                                </a>
                            </div>

                            <div>

                            </div>

                        </div>
                    </div>
                    <div className='flex flex-col  left__card_items'>
                        <div className='flex justify-end mt-10'>
                            <div className='sort_group'></div>
                        </div>
                        {/* <Cartone red_icon={likable} children={<React.Fragment>
                            <Tittle_captions caption="Beedrooms" text="5 BHK" />
                            <Tittle_captions caption="Type" text="Villa" />
                            <Tittle_captions caption="Sq. Feet" text="4000" />
                        </React.Fragment>} />
                        <Cartone red_icon={likable} />
                        <Cartone red_icon={likable} /> */}
                    </div>
                </div>
            </Container>
            <Footer />


        </div>
    )
}

export default Products
    