import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    steps: 0,

}

const animateSlides = createSlice({
    initialState: initialState,
    name: "animateSlidesSlice",
    reducers: {
        addsteps(state) {
            state.steps++;
        },
        minussteps(state) {
            state.steps--;
        }
    }


});

export const { addsteps,minussteps } = animateSlides.actions;

export default animateSlides.reducer;