import styled from "styled-components";
const Header = styled.div`
height: 50px;


`;
const HeaderSearch = () => <Header className='flex justify-between items-center header_section_top_section_desktop bg-blue-900'>
    <div className="logo flex justify-center font_custome_Linque capitalize text-white font-extrabold items-center">
        Jabuh
    </div>
    <div className="rounded-lg flex items-center  search_section">
        <div className="search_Icon flex items-center justify-center ">
            <i className='fa-solid fa-search text-gray-400'></i>
        </div>
        <input type="text" placeholder='Search' />
        <div className='flex gap-1 items-center mx-3'>
            <div className="icon__shortcut bg-gray-800 text-gray-400 font-medium rounded-md">Ctrl</div>
            <div className="icon__shortcut bg-gray-800 text-gray-400 font-medium rounded-md">J</div>
        </div>
    </div>

    <div className=" flex items-center  gap-3 mr-4 user_option_components_avatar">
        <div className=" hover:bg-gray-300 flex cursor-pointer u_highlights rounded-md">
            <div className="icon_u relative p-1 px-2 ">

                <div className="absolute bg-red-600 flex justify-center items-center rounded-full text-white font-medium icon_badge">
                    1
                </div>

                <i className='fa-regular fa-bell text-white' style={{ fontSize: 17 }}></i>
            </div>


        </div>

        <div className="avatar cursor-pointer u_highlights  rounded-lg custome_ouliune flex px-1 py-1  items-center gap-2 ">
            <div className="icon__shopdata rounded-lg bg-red-600 flex    justify-center items-center">
                MS</div>
            <span className='text-white font-medium'> My Store</span>
        </div>
    </div>
</Header>

export default HeaderSearch;