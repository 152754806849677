import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isopen: false,
    selectedmenu: null,
}

const Submenu = createSlice({
    initialState: initialState,
    name: "submenuSlice",
    reducers: {
        isopen(state) {
            state.isopen = !state.isopen;
        },

        selectnavsection(state, action) {
            state.selectedmenu = action.payload.id
        }
    }


});

export const { isopen } = Submenu.actions;

export default Submenu.reducer;