import styled from "styled-components";

export const Container = styled.div`

width: 60%;
margin: 0 auto;


@media(max-width: 48rem){
width: 98% !important;

}

`;